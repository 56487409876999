import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import ApplicantFormHorizontal from "./../components/Global/ApplicantFormHorizontal"


import _ from 'lodash'
import Img from "gatsby-image"
import Footer from "../components/FooterMenu"


import logoWhite from "../images/enimeris-logo-website-white.png";
import "./../components/index.css"




function ergasiaStinEnimeris(props) {
    const data = props.data.wpgraphql
    console.log(data)
    return (
        <div>

            <Helmet>
                <html lang={"el"} />
                <title>{data.page.seo.title}</title>
                <meta name="description" content={data.page.seo.description} />
                <link rel="canonical" href={data.page.seo.canonical} />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />
                <meta property="og:title" content={data.page.seo.opengraphTitle} />
                <meta property="og:description" content={data.page.seo.opengraphDescription} />
                <meta property="og:image" content={data.page.seo.opengraphImage ? data.page.seo.opengraphImage.sourceUrl : ""} />
                <meta property="og:image:width" content="598" />
                <meta property="og:image:height" content="399" />
            </Helmet>

            <div>
                <div className="text-white synergates-intro flex justify-center">
                    <Link to={"/"}>
                        <img className="w-56 md:w-64 m-auto py-8" src={logoWhite} alt="white-logo" />
                    </Link>
                </div>
                <div className="container px-4 md:px-0 py-16 m-auto">
                    <h1 className="our-carreer-title">
                        {data.page.companyCarreerPageAcf.h1Title}
                    </h1>
                    <p className="our-c-sub my-4">
                        {data.page.companyCarreerPageAcf.subTitle}
                    </p>

                    <a href="#positions" className="our-c-bttn px-6 py-3 block m-auto mt-8" style={{ width: " 240px" }}>
                        {data.page.companyCarreerPageAcf.introButtton}
                    </a>
                </div>

                <div className="divider" />

                <div className="container m-auto py-24">
                    <div className="px-4 md:px-0">
                        <h3 className="our-carreer-title mb-12" style={{ fontSize: "3rem" }}>
                            {data.page.companyCarreerPageAcf.valuesTitle}
                        </h3>
                    </div>
                    <div>
                        {data.page.companyCarreerPageAcf.valuesEditor.map((val, i) => (
                            <div className={`flex flex-wrap items-center ${i === 0 ? 'pt-0' : 'pt-8'}`}>
                                <div className={`block w-full md:w-2/5 ${i % 2 === 0 ? 'order-1' : 'order-1 md:order-2'} `}>
                                    <Img fluid={val.image.imageFile.childImageSharp.fluid} alt={val.image ? val.image.altText : ""} style={{ width: "340px", height: "340px", margin: "auto" }} />
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: val.contentEditor }} className={`${i % 2 === 0 ? 'order-2 md:pl-8 leading-loose' : 'order-2 md:order-1 md:pr-8 leading-loose'} text-xl w-full md:w-3/5 px-4 md:px-0`}></div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="divider" />

                <div className="container m-auto py-24 px-2 md:px-0" id="positions">
                    {data.jobCompany?.ourJobs?.edges?.length > 0 && (
                        <div>
                            <h3 className="our-carreer-title mb-12" style={{ fontSize: "3rem" }}>
                                {data.page.companyCarreerPageAcf.availableJobs}
                            </h3>
                        </div>
                    )}
                    <div className="flex flex-wrap items-center">
                        {data.jobCompany?.ourJobs?.edges?.length > 0 && data.jobCompany.ourJobs?.edges.map((job, i) => (
                            <div className="w-full md:w-1/3 pt-8 md:pt-0" key={`our-jobs-${i}`}>
                                {console.log(job)}
                                <h4>
                                    {job.node.title}
                                </h4>
                                <div className="mb-4" dangerouslySetInnerHTML={{ __html: job.node.excerpt }}></div>
                                <Link className="our-c-bttn px-4 py-2 inline mt-3" to={`/ergasia-stin-enimeris/${job.node.slug}`}>Μάθετε Περισσότερα</Link>
                            </div>
                        ))}

                        { data.jobCompany.ourJobs.edges.length === 0 && (
                            <div className="flex flex-wrap items-center ese" > 
                                <ApplicantFormHorizontal allowPhoneNumber={true} allowCv={true} jobId={"666"} jobName={"ergasiaStnEnimeris"} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer footerMenu={data.footer} />
        </div>
    )
}


export default ergasiaStinEnimeris

export const pageQuery = graphql`
	query {
		wpgraphql {
			page(id: "cG9zdDoxNzQ4") {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }

                companyCarreerPageAcf {
                    h1Title
                    subTitle
                    introButtton
                    availableJobs
                    valuesTitle
                    valuesEditor{
                        image{
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp{
                                    fluid(maxWidth: 2080, quality:60){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            } 
                        }
                        contentEditor
                    }
                }
            }

            jobCompany(id:"enimeris", idType: SLUG){
                ourJobs {
                  edges{
                        node{
                            title
                            excerpt
                            slug
                        }
                    }
                }
            }

            footer: menu(id:"bmF2X21lbnU6MjQ="){
                menuId
                id
                slug
                name
                menuItems {
                    edges {
                        node {
                            label
                            url
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
		}
	}
`
