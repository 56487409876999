import React, { useState, useRef, useEffect } from 'react'
import { useDropzone } from 'react-dropzone';

import Img from "gatsby-image"
import Formsy from 'formsy-react';
import axios from "axios"

import FormsyInput from "./../Formsy/formsyInput"

import "./ApplicantFormHorizontal.css";


function ApplicantFormHorizontal(props) {
    const data = props;
    const formRef = useRef(null);

    const [canSubmit, setCanSubmit] = useState(false);
    const [canSubmit2, setCanSubmit2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [privacyTermsStatus, setPrivacyTermsStatus] = useState(false);
    const [cvOptIn, setCvOptIn] = useState(false);

    const [files, setFiles] = useState([]);
    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
        console.log(files)
    }, [files]);

    useEffect(() => {
        if (canSubmit) {
            setShowErrorMessage(false)
        }
    }, [canSubmit])

    useEffect(() => {

        if (privacyTermsStatus && canSubmit && (!cvOptIn && (!props.allowCv && files.length <= 0))) {
            setCanSubmit2(true)
        } else if ((privacyTermsStatus && canSubmit && (cvOptIn && files.length > 0))) {
            setCanSubmit2(true)
        } else {
            setCanSubmit2(false)
        }
    }, [privacyTermsStatus, cvOptIn])

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf,.doc,.docx',
        maxSize: 5242880,
        multiple: false,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
        onDropRejected: (e) => {
            console.log(e)
            console.log("Invoked");
        }
    });

    function resetFile(){
        setFiles([]);
    }


    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {
        console.log(model, files)

        if (model.lastName && model.name && model.email && files.length > 0) {
            sendApplication(model, files);
        } else if ((model.lastName && model.name && model.email) && (files.length > 0)) {
            sendApplication(model, files);
        } else if (model.lastName && model.name && model.email) {
            sendApplication(model);
        } else {
            setShowErrorMessage(true)
        }
    }

    function sendApplication(model, files) {
        setLoading(true)

        console.log(model)

        let formData = new FormData();
        if (files) {
            formData.append('file', files[0]);
        }
        formData.append('model', JSON.stringify(model));
        formData.append('jobId', props.jobId);
        formData.append('jobName', props.jobName);

        // let payload = {
        //     ...model,
        //     language: props.language
        // }

        axios.post(`https://f2.enimeris.com/wp-json/myplugin/v1/addApplicant`, formData)
            .then(function (response) {
                console.log(response)
                // handle success
                setEmailFailed(false)
                setLoading(false)
                setEmailSent(true)
                setEmailResponse(response.data)
            })
            .catch(function (error) {
                // handle error
                setLoading(false)
                setEmailFailed(true)
                setEmailResponse("Υπήρξε πρόβλημα κατα την αποστολή. Δοκιμάστε αργότερα")
            })
    }

    function test(e) {
        e.preventDefault();
        setShowErrorMessage(true)
    }

    return (
        <React.Fragment>
            <div className="w-full md:w-3/5">
                <div className="p-8">
                    <h3 className="text-4xl">Εκδήλωση ενδιαφέροντος για εργασία στην Enimeris</h3>
                    <p>Αυτή τη στιγμή δεν υπάρχουν διαθέσιμες θέσεις εργασίας. Μπορείτε να συμπληρώστε τη φόρμα, για να εκδηλώσετε ενδιαφέρον απασχόλησης στην Enimeris για την κάλυψη μελλοντικών θέσεων εργασίας.</p>
                </div>
            </div>
            <div className={`w-full md:w-2/5 right-tab-cont` }>
                <div className={`m-auto ${emailSent ? "max-w-none" : "max-w-xl"} px-0 md:px-12 relative`} >
                    {loading && (
                        <div className="loader-container">
                            <div className="loader">Loading...</div>
                        </div>
                    )}

                    {emailFailed && (
                        <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                    )}

                    {!emailSent
                        ?
                        <Formsy
                            onValidSubmit={submit}
                            onValid={enableButton}
                            onInvalid={disableButton}
                            className={loading ? "opacity-25" : "opacity-100"}
                            ref={formRef}
                        >
                            <div className="mt-12">
                                <FormsyInput
                                    name="name"
                                    placeholder="Όνομα *"
                                    classes="mt-4"
                                    horizontal={true}
                                    required
                                />
                                <FormsyInput
                                    name="lastName"
                                    placeholder="Επώνυμο *"
                                    classes="mt-4"
                                    horizontal={true}
                                    required
                                />
                                <FormsyInput
                                    name="email"
                                    validations={"isEmail"}
                                    validationErrors={{
                                        isEmail: "Το email δεν είναι έγκυρο",
                                    }}
                                    placeholder="Email *"
                                    classes="mt-4"
                                    horizontal={true}
                                    required
                                />
                                {props.allowPhoneNumber && (
                                    <FormsyInput
                                        name="telephone"
                                        validations={"minLength:10"}
                                        type="number"
                                        validationErrors={{
                                            minLength: "Το τηλέφωνο δεν είναι έγκυρο",
                                        }}
                                        placeholder="Τηλέφωνο"
                                        horizontal={true}
                                        classes="mt-4"
                                    />

                                )}

                                <div className="text-black" {...getRootProps({ className: "dropzoneHorizontal" })}>
                                    <input {...getInputProps()} />
                                    <img src={props.icon ? props.icon : null} style={{ width: "150px" }} />
                                    <p>Μεταφόρτωση Βιογραφικού {props.allowCv ? "*" : ""}</p>

                                    {files.length > 0 && (
                                        <span>
                                            {files[0].name}
                                        </span>
                                    )}

                                </div>

                                {files.length > 0 && (
                                    <div onClick={resetFile} className="clear-cv">Καθαρισμός</div>
                                )}

                                <div className="mt-8">
                                    <input
                                        name="privacy-terms"
                                        type="checkbox"
                                        checked={privacyTermsStatus}
                                        onChange={() => setPrivacyTermsStatus(!privacyTermsStatus)}
                                    />
                                    <span className="pl-2 text-black">Αποδέχομαι την
                                    <a href="https://enimeris.com/politiki-aporitou/" target="_blank" className="px-1 underline">Πολιτική Απορρήτου</a>
                                    και τους
                                    <a href="https://enimeris.com/oroi-kai-proipotheseis/" target="_blank" className="pl-1 underline">Όρους και Προϋποθέσεις Χρήσης</a>.
                                </span>
                                </div>

                                {files.length > 0 && (
                                    <div>
                                        <input
                                            name="privacy-terms"
                                            type="checkbox"
                                            checked={cvOptIn}
                                            onChange={() => setCvOptIn(!cvOptIn)}
                                        />
                                        <span className="pl-2 text-black">Συμφωνώ με την αποθήκευση και επεξεργασία του βιογραφικού μου από την Enimeris μέχρι την πλήρωση της θέσης για την οποία το υπέβαλα.</span>
                                    </div>
                                )}


                            </div>
                            {(canSubmit && canSubmit2)
                                ?
                                <button className={"bttn-active cf-bttn-horizontal"}>
                                    Υποβολή
                            </button>
                                :
                                <button className={"cf-bttn-horizontal"} onClick={(e) => test(e)}>
                                    Υποβολή
                            </button>
                            }
                            {showErrorMessage && (
                                <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                            )}
                        </Formsy>
                        :
                        <div >
                            <div className="email-response-hor" dangerouslySetInnerHTML={{ __html: emailResponse.replace("υποψηφιότητά","αίτησή") }} />
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>
    )
}

export default ApplicantFormHorizontal;